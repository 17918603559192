import React, { useEffect } from "react";

const Main = ({ match: { params } }) => {
  useEffect(() => {
    onInitData();
    console.log("pa", params.code);
  }, [params.code]);

  const onInitData = async () => {
    try {
      const response = await fetch(
        `https://apidevv2.stnhd.com/api/MstShortLink/detail/${params.code}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          // body: JSON.stringify(data),
        }
      );
      const resJson = await response.json();
      if (resJson?.retCode === 0) {
        window.location.replace(resJson.data?.link);
      } else {
        console.log("Link không khả dụng");
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  return <div>Đang tải....</div>;
};

export default Main;
