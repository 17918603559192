import React from "react";
import { Route, Switch } from "react-router-dom";

import NoMatch from "./components/NoMatch/NoMatch";
import Main from "./containers/Main";

const App = () => {
  return (
    <div className="App">
      <Switch>
        <Route path="/:code" component={Main} />
        <Route component={NoMatch} />
      </Switch>
    </div>
  );
};

export default App;
